'use strict';

$(
	function() {
		app.assignNumberControl();
		app.setSelectedLink();
		app.addRegisterCheckBoxes();
		app.attachSvgEvents();
		app.initACBL();
		app.setBackUrls();
		app.addCoverSpan();
		app.topMenu.init();
		if(!app.isMobile()) {
			$(window).scroll(
				function(){
					app.floatingHeader();
				}
			);
			app.floatingHeader();
		}
		app.assignProductOver();
		app.initializeScrollButton();
		app.hamburger.init({
			look: 1
		});
		app.extraMenu && app.extraMenu.init({side: 'left'});
		$('.menu > ul > li').addClass('schema-brd-color-over');
	}
);

var app = {
	_VERSION: 2,
	_vars: {
		v: 1.7,
		tmp: 20,
		putAttributesToProdItemsDirectly: true,
		_guid: 'adc239cd-8ba8-4793-9abb-f30f387efa6b',
		_customFonts: {
			FontAwesome: true
		}
	},
	responsiveMode: true,
	indexLinkPos: 0,
	messages: {},
	productAvailableQuantityUrl: null,
	optionsWereInitialized: false,
	fixTop: 0,
	showSearch: function() {
		if ($('.li-search .ul-mega-1').hasClass('hidden')) {
			$('.li-search').addClass('hover');
			$('.li-search .ul-mega-1, .li-search .delimiter').removeClass('hidden');
		} else {
			$('.li-search').removeClass('hover');
			$('.li-search .ul-mega-1, .li-search .delimiter').addClass('hidden');
		}
	},
	runIndexCarusel: function() {
		app.runCarusel({
			innerHTML: '<div class="zoomer"><div class="inner"></div></div>'
		});
		$('.jcarousel-list li').each(
			function() {
				var href = $(this).find('.image a').attr('href');
				$(this).find('.zoomer').click(
					function() {
						document.location = href;
					}
				)
			}
		)
	},
	doBeforePriceSliderShown: function(trg) {
		var color = app.getClassColor('schema-color');
		$('.noUi-connect').css('background-color', color);
	}
};